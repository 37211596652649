import {
  Box,
  Typography,
  Grid,
  Skeleton,
  TextField,
  Button,
  IconButton,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import {
  getChannelConfigurationsAPI,
  getDocTypesAPI,
} from "../../../../../../services"
import { Search, RemoveCircle } from "@mui/icons-material"
import {
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import { triggerData } from "./data"
import { Accord } from "./Accordion"

interface IProps {
  triggerType: TChannelTypeName
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  selectedAction: INodeItem
  selections: Record<string, boolean>
  setSelections: Dispatch<SetStateAction<Record<string, boolean>>>
}

const CHANNEL_ID = "ChannelId"
const CHANNEL_TYPE = "ChannelType"
const DOC_TYPE = "DocType"
const LAWYER = "Lawyer"
const REFERATE = "Referate"

export const GeneralTrigger = (props: IProps) => {
  const {
    triggerType,
    handleChange,
    selectedAction,
    selections,
    setSelections,
  } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>("")
  const [selectAllItems, setSelectAllItems] = useState<Record<string, boolean>>(
    {
      channelType: false,
      channels: false,
      docTypes: false,
      referate: false,
      lawyer: false,
    },
  )

  const [currentExpanded, setCurrentExpanded] = useState<
    "channels" | "docTypes" | "channelType" | "lawyer" | "referate" | ""
  >("")

  const {
    data: channelConfigurations,
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: () => getChannelConfigurationsAPI(triggerType),
    refetchOnMount: true,
  })

  const {
    data: docTypes,
    isPending: isPendingDocTypes,
    isRefetching: isRefetchingDocTypes,
  } = useQuery({
    queryKey: ["doc-types"],
    queryFn: () => (triggerType === "Email" ? getDocTypesAPI() : []),
    refetchOnMount: true,
  })

  const channels = selectedAction?.inputs?.[CHANNEL_ID] || "''"
  const docs = selectedAction?.inputs?.[DOC_TYPE] || "''"
  const lawyer = selectedAction?.inputs?.[LAWYER] || "''"
  const referate = selectedAction?.inputs?.[REFERATE] || "''"
  const channelType = selectedAction?.inputs?.[CHANNEL_TYPE] || "''"

  const setInitialSelectAll: (
    querySource: any[] | undefined,
    source: any[],
    type: string,
  ) => void = (querySource, source, type) => {
    if (type !== "docTypes") {
      setSelectAllItems((prev: any) => {
        return {
          ...prev,
          [type]: querySource
            ?.map((item) => item.id)
            .every((id) => source?.includes(id)),
        }
      })
    } else {
      setSelectAllItems((prev: any) => {
        return {
          ...prev,
          [type]: querySource
            ?.map((document) => document.docCategory)
            .every((docCategory) => source?.includes(docCategory)),
        }
      })
    }
  }
  const filterItems = (items: any, type: string) => {
    return search
      ? items?.filter((item: any) =>
          type === "channels"
            ? item?.channelName?.toLowerCase()?.includes(search?.toLowerCase())
            : item?.description?.toLowerCase()?.includes(search?.toLowerCase()),
        )
      : items
  }

  const useFilteredItems = (
    itemsArray: any[],
    type: string,
    search: string,
  ) => {
    return useMemo(() => filterItems(itemsArray, type), [itemsArray, search])
  }

  const filteredChannels = useFilteredItems(
    channelConfigurations!,
    "channels",
    search,
  )
  const filteredDocTypes = useFilteredItems(docTypes!, "docTypes", search)
  const filteredLawyer = useFilteredItems(triggerData.Lawyer, "lawyer", search)
  const filteredReferate = useFilteredItems(
    triggerData.Referate,
    "referate",
    search,
  )
  const filteredChannelType = useFilteredItems(
    triggerData.ChannelType,
    "channelType",
    search,
  )

  const initialSelectAllArr = [
    { 0: channelConfigurations, 1: channels, 2: "channels" },
    { 0: docTypes, 1: docs, 2: "docTypes" },
    { 0: triggerData.Lawyer, 1: lawyer, 2: "lawyer" },
    { 0: triggerData.Referate, 1: referate, 2: "referate" },
    { 0: triggerData.ChannelType, 1: channelType, 2: "channelType" },
  ]

  useEffect(() => {
    initialSelectAllArr?.forEach((item) => {
      if (item[0]) {
        setInitialSelectAll(item[0], item[1], item[2])
      }
    })

    handleChange(selectedAction, "input", triggerType, CHANNEL_TYPE)
  }, [initialSelectAllArr, triggerType])

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const isLoadingDocTypes = isPendingDocTypes || isRefetchingDocTypes

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ ml: 1 }} variant="largeMedium">
          {t("step")} {t("id")}: {selectedAction?.id}
        </Typography>

        <TextField
          sx={{
            visibility: Object.values(selections).includes(true)
              ? "visible"
              : "hidden",
          }}
          label={t("search")}
          variant="outlined"
          size="small"
          disabled={isLoadingChannelConfigs}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{ startAdornment: <Search htmlColor={colors.gray2} /> }}
        />
      </Box>
      {Object.values(selections).includes(false) && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            borderBottom: "none",
          }}
        >
          {Object.keys(selections).map((sel) => {
            if (triggerType !== "Email" && sel === "Document Type") {
              return null
            }
            return !selections[sel] ? (
              <Button
                onClick={() =>
                  setSelections((prev) => ({ ...prev, [sel]: true }))
                }
                sx={{
                  width: "175px",
                  mr: 1,
                  paddingLeft: "10px !important",
                  display: "flex",
                  justifyContent: "flex-start",
                  mb: 2,
                }}
                variant="outlined"
              >
                <AddIcon sx={{ mr: 1 }} />
                {sel}
              </Button>
            ) : (
              <></>
            )
          })}
        </Box>
      )}
      {selections["Channel Type"] && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Accord
            currentExpanded={currentExpanded}
            setCurrentExpanded={setCurrentExpanded}
            selectAll={selectAllItems.channelType}
            items={filteredChannelType}
            source={channelType}
            type="channelType"
            title="Channel Type"
            selectAllItems={selectAllItems}
            setSelectAllItems={setSelectAllItems}
            querySource={triggerData.ChannelType}
            selectedAction={selectedAction}
            handleChange={handleChange}
            changeType={CHANNEL_TYPE}
          />
          <IconButton
            onClick={() =>
              setSelections((prev) => ({ ...prev, "Channel Type": false }))
            }
            color="error"
            sx={{
              width: "2.5%",
              height: "40px",
            }}
          >
            <RemoveCircle />
          </IconButton>
        </Box>
      )}
      {isLoadingChannelConfigs
        ? Array.from({ length: 5 }).map((_, index) => (
            <Box key={index}>
              <Skeleton variant="rounded" height="80px" />
            </Box>
          ))
        : selections.Channels && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mt: 2,
                alignItems: "center",
              }}
            >
              <Accord
                currentExpanded={currentExpanded}
                setCurrentExpanded={setCurrentExpanded}
                selectAll={selectAllItems.channels}
                items={filteredChannels}
                source={channels}
                type="channels"
                title="Channels"
                selectAllItems={selectAllItems}
                setSelectAllItems={setSelectAllItems}
                querySource={channelConfigurations}
                selectedAction={selectedAction}
                handleChange={handleChange}
                changeType={CHANNEL_ID}
              />
              <IconButton
                onClick={() => {
                  setSelections((prev) => ({ ...prev, Channels: false }))
                  handleChange(selectedAction, "input", "''", CHANNEL_ID)
                }}
                color="error"
                sx={{
                  width: "2.5%",
                  height: "40px",
                }}
              >
                <RemoveCircle />
              </IconButton>
            </Box>
          )}
      {triggerType === "Email" && selections["Document Type"] && (
        <Box>
          {isLoadingDocTypes ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={6} sm={3} md={2} lg={1.5} key={index}>
                <Box display="flex" flexDirection="column" gap="2px">
                  <Skeleton variant="rounded" height="80px" />
                  <Skeleton variant="text" width="60%" />
                </Box>
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Accord
                currentExpanded={currentExpanded}
                setCurrentExpanded={setCurrentExpanded}
                selectAll={selectAllItems.docTypes}
                items={filteredDocTypes}
                source={docs}
                type="docTypes"
                title="Document Type"
                selectAllItems={selectAllItems}
                setSelectAllItems={setSelectAllItems}
                querySource={docTypes}
                selectedAction={selectedAction}
                handleChange={handleChange}
                changeType={DOC_TYPE}
              />
              <IconButton
                onClick={() => {
                  setSelections((prev) => ({ ...prev, "Document Type": false }))
                  handleChange(selectedAction, "input", "''", DOC_TYPE)
                }}
                color="error"
                sx={{ width: "2.5%", height: "40px" }}
              >
                <RemoveCircle />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
      {selections.Lawyer && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Accord
            currentExpanded={currentExpanded}
            setCurrentExpanded={setCurrentExpanded}
            selectAll={selectAllItems.lawyer}
            items={filteredLawyer}
            source={lawyer}
            type="lawyer"
            title="Lawyer"
            selectAllItems={selectAllItems}
            setSelectAllItems={setSelectAllItems}
            querySource={triggerData.Lawyer}
            selectedAction={selectedAction}
            handleChange={handleChange}
            changeType={LAWYER}
          />
          <IconButton
            onClick={() => {
              setSelections((prev) => ({ ...prev, Lawyer: false }))
              handleChange(selectedAction, "input", "''", LAWYER)
            }}
            color="error"
            sx={{
              width: "2.5%",
              height: "40px",
            }}
          >
            <RemoveCircle />
          </IconButton>
        </Box>
      )}
      {selections.Referate && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Accord
            currentExpanded={currentExpanded}
            setCurrentExpanded={setCurrentExpanded}
            selectAll={selectAllItems.referate}
            items={filteredReferate}
            source={referate}
            type="referate"
            title="Referate"
            selectAllItems={selectAllItems}
            setSelectAllItems={setSelectAllItems}
            querySource={triggerData.Referate}
            selectedAction={selectedAction}
            handleChange={handleChange}
            changeType={REFERATE}
          />
          <IconButton
            onClick={() => {
              setSelections((prev) => ({ ...prev, Referate: false }))
              handleChange(selectedAction, "input", "''", REFERATE)
            }}
            color="error"
            sx={{
              width: "2.5%",
              height: "40px",
            }}
          >
            <RemoveCircle />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}
