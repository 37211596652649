/* eslint-disable quotes */
import { type Edge, type Node, Position } from "reactflow"
import dagre from "@dagrejs/dagre"
import { colors } from "../../../utils"
import { ACTION_METHODS, CORE_METHODS, TRIGGER_METHODS } from "./constants"
import {
  AccountTreeOutlined,
  AltRoute,
  Animation,
  Assignment,
  Bolt,
  Calculate,
  CalendarToday,
  CallSplit,
  Diamond,
  ForwardToInbox,
  Http,
  Inbox,
  MarkEmailUnread,
  Outbox,
  OutlinedFlag,
  PausePresentation,
  PostAdd,
  QueryBuilder,
  Terminal,
  TextSnippet,
  BackupTable,
} from "@mui/icons-material"
import DescriptionIcon from "@mui/icons-material/Description"

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 500
  const nodeHeight = 150

  dagreGraph.setGraph({ rankdir: "TB" })

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = Position.Bottom
    node.sourcePosition = Position.Top

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    }
  })

  return { nodes, edges }
}

const typeMap: Record<number, string> = {
  0: "trigger",
  1: "action",
  2: "core",
  3: "dropzone",
  4: "posteingang",
  5: "postausgang",
  6: "wiedervorlage",
  7: "genericstep",
}
const typeMapReverse: Record<string, number> = {
  trigger: 0,
  action: 1,
  core: 2,
  dropzone: 3,
  posteingang: 4,
  postausgang: 5,
  wiedervorlage: 6,
  genericstep: 7,
}

const createNodeFromStep = (step: IWorkflowStep, type: string): Node => ({
  id: step.id,
  type,
  data: {
    type: typeMap[step.type],
    action: step.stepType,
    label: step.name ?? step.id,
    nextStepId: step.nextStepId,
    id: step.id,
    icon: step.icon,
    inputs: step?.inputs,
    outputs: step?.outputs,
    do: [],
  },
  position: { x: 0, y: 0 },
})

export const transformStepToNode = (step: IWorkflowStep): Node[] => {
  const nodes: Node[] = []
  if (typeMap[step.type] === "dropzone") {
    nodes.push({
      id: step.id,
      type: "dropzoneCard",
      data: {
        type: typeMap[step.type],
        title: "step",
        id: step.id,
      },
      position: { x: 0, y: 0 },
    })
  }

  if (step.nextStepId === null || step.nextStepId === "") {
    nodes.push({
      id: `${step.id}-dropzone`,
      type: "dropzoneCard",
      data: { id: `${step.id}-dropzone`, title: "step" },
      position: { x: 0, y: 0 },
    })
  }

  if (step.stepType === "If") {
    const parentNode = createNodeFromStep(step, "boolCard")
    nodes.push(parentNode)

    if (step.do?.length > 0) {
      step.do[0].forEach((trueBranch: IWorkflowStep) => {
        nodes.push(...transformStepToNode(trueBranch))
      })
    }

    return nodes
  }

  const defaultNode = createNodeFromStep(step, "actionCard")
  nodes.push(defaultNode)
  if (step.do?.length > 0) {
    step.do.forEach((nestedStep: IWorkflowStep) => {
      nodes.push(...transformStepToNode(nestedStep))
    })
  }

  return nodes
}

const createStepFromNode = (node: Node, edges: Edge[]) => {
  const nextStepId = edges.find((edge) => edge.source === node.id)?.target ?? ""
  const checkedNextId = nextStepId.includes("-dropzone") ? "" : nextStepId

  const step: IWorkflowStep = {
    id: node.id,
    type: typeMapReverse[node.data.type],
    stepType: node.data?.action as string,
    icon: node.data.icon,
    inputs: node.data.inputs,
    cancelCondition: null,
    errorBehavior: null,
    compensateWith: [],
    saga: false,
    nextStepId: checkedNextId,
    outputs: node.data.outputs,
    do: [],
    retryInterval: null,
    name: node.data.label,
    userInteraction: false,
  }

  if (node.type === "dropzoneCard") {
    step.type = 3
    step.icon = ""
    step.inputs = null
    step.outputs = null
    step.selectNextStep = null
    step.do = null
  }

  return step
}

export const transformNodeToStep = (
  nodes: Node[],
  edges: Edge[],
): IWorkflowStep[] => {
  const steps: IWorkflowStep[] = []

  nodes.forEach((node) => {
    if (node.id.includes("-dropzone") || /If-.*-B1$/.test(node?.id))
      // It is an if true branch if it begins with "If-" and ends with "B1"
      return []

    if (node.id.includes("If-")) {
      const ifStep = createStepFromNode(node, edges)
      const trueBranch = nodes.find((n) =>
        new RegExp(`^${node.id}-B1$`).test(n?.id),
      )
      const falseBranch = nodes.find((n) =>
        new RegExp(`^${node.id}-B2$`).test(n?.id),
      )

      if (trueBranch) {
        const trueBranchStep = createStepFromNode(trueBranch, edges)
        ifStep.do.push([trueBranchStep])
      }
      if (falseBranch) {
        ifStep.nextStepId = falseBranch.id
      }

      steps.push(ifStep)
    } else {
      steps.push(createStepFromNode(node, edges))
    }
  })

  return steps
}

const createEdge = (parent: IWorkflowStep | undefined, step: IWorkflowStep) => {
  let label

  if (/If-.*-B1$/.test(step?.id)) {
    label = "True"
  } else if (/If-.*-B2$/.test(step?.id)) {
    label = "False"
  }

  const newEdge: Edge = {
    id: `e${parent?.id}-${step?.id}`,
    source: parent?.id!,
    target: step?.id,
    label,
    data: { parent: parent?.id },
    type: parent?.stepType === "If" ? "" : "addButtonEdge",
    style: { stroke: colors.gray3 },
  }

  return newEdge
}

export const createEdges = (allSteps: IWorkflowStep[]) => {
  const edges: Edge[] = []
  allSteps.forEach((step) => {
    let parent: any
    allSteps.forEach((s) => {
      const nestedStep = s.do?.[0]?.[0]
      if (nestedStep?.nextStepId === step.id) {
        parent = nestedStep
      }

      if (s.nextStepId === step.id) {
        parent = s
      }
    })

    if (step.nextStepId === null || step.nextStepId === "") {
      edges.push({
        id: `${step.id}-edge`,
        source: step.id,
        target: `${step.id}-dropzone`,
        type: "",
      })
    }

    if (step.stepType === "If") {
      const trueBranch = step.do[0]

      edges.push(createEdge(parent, step))
      edges.push(createEdge(step, trueBranch[0]))
    } else if (parent) {
      edges.push(createEdge(parent, step))
    } else {
      edges.push(createEdge(parent, step))
    }
  })

  return edges
}

export const getCategoryItems = (category: string): IMethodItem[] => {
  switch (category) {
    case "triggers":
      return TRIGGER_METHODS
    case "actions":
      return ACTION_METHODS
    case "core":
      return CORE_METHODS
    default:
      return []
  }
}

export const getNodeIcon = (icon: string, type: string) => {
  switch (type) {
    case "trigger":
      switch (icon) {
        case "forwardToInbox":
          return <ForwardToInbox />
        case "calendarToday":
          return <CalendarToday />
        case "textSnippet":
          return <TextSnippet />
        case "claim":
          return <Assignment />
        case "httpRequest":
          return <Http />
        case "incomingEmail":
          return <MarkEmailUnread />
        default:
          return <Bolt />
      }
    case "posteingang":
    case "postausgang":
    case "wiedervorlage":
    case "genericstep":
    case "action":
      switch (icon) {
        case "outgoingEmail":
          return <Outbox />
        case "inbox":
          return <Inbox />
        case "postAdd":
          return <PostAdd />
        case "data":
          return <DescriptionIcon />
        case "table":
          return <BackupTable />
        default:
          return <Animation />
      }
    case "core":
      switch (icon) {
        case "pausePresentation":
          return <PausePresentation />
        case "altRoute":
          return <AltRoute />
        case "terminal":
          return <Terminal />
        case "splitArrows":
          return <CallSplit />
        case "calculate":
          return <Calculate />
        case "queryBuilder":
          return <QueryBuilder />
        case "accountTree":
          return <AccountTreeOutlined />
        case "outlinedFlag":
          return <OutlinedFlag />
        default:
          return <Diamond />
      }
    default:
      return null
  }
}

export const transformDraggedItem = (active: any): Partial<IMethodItem> => {
  const draggedItem = active.data.current?.item
  return {
    action: draggedItem?.action,
    label: draggedItem?.label,
    type: draggedItem?.type,
    icon: draggedItem?.icon,
  }
}

export const parseInputValue = (value: any) => {
  if (
    typeof value === "string" &&
    value?.length &&
    ((value.startsWith("'") && value.endsWith("'")) ||
      (value.startsWith('"') && value.endsWith('"')))
  ) {
    return value.slice(1, value.length - 1)
  }

  return value
}

export const mapInputValue = (
  value: any,
  stepType: TStepTypes,
  controlName: string,
) => {
  if (
    typeof value === "object" ||
    (stepType === "If" && controlName === "Condition")
  ) {
    return value
  } else if (typeof value === "string") {
    return `'${value}'`
  }

  return value
}

export const createNewIds = (
  over: any,
  transformedItem: Partial<IMethodItem>,
  nodesLength: number,
) => {
  // If over.id, which is the dropzone id, contains '-B{some number}' and ends with '-dropzone' then it is the dropzone of a branch
  // in which case, the id of the transformed item should be changed to '{parent id}-B{some number signifying branch order}
  const newId = /^-B\d+.*-dropzone$/.test(over?.id)
    ? over?.id?.toString().replace("-dropzone", "")
    : `${transformedItem?.action}-${nodesLength + 1}`

  return newId
}

export const addRegularNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  const targetLowerEdge = edges.find((edge) => edge.source === over?.id)
  const targetLowerNode = nodes.find(
    (node) => node.id === targetLowerEdge?.target,
  )

  // If a node is added in the middle, there should be a node beneath it, otherwise it is the last node
  if (targetLowerNode) {
    const updatedNodes = nodes.map((node) =>
      node.id === over?.id
        ? {
            ...node,
            type: "actionCard",
            data: {
              ...transformedItem,
            },
          }
        : node,
    )

    const updatedEdges = edges.map((edge) =>
      edge.target === over?.id || edge.source === over?.id
        ? {
            ...edge,
            id: `e${edge.source}-${edge.target}`,
            type: edge.source?.includes("If-") ? "" : "addButtonEdge",
          }
        : edge,
    )

    return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
  }

  const updatedNodes = nodes
    .concat({
      id: `${newId}-dropzone`,
      type: "dropzoneCard",
      data: { id: `${newId}-dropzone`, title: "step" },
      position: { x: 0, y: 0 },
    })
    .map((node) => {
      if (node.id === over?.id)
        return {
          ...node,
          id: newId,
          type: "actionCard",
          data: {
            ...transformedItem,
          },
        }
      return node
    })

  const updatedEdges = edges
    .concat({
      id: `${newId}-edge`,
      source: newId,
      target: `${newId}-dropzone`,
      type: "",
    })
    .map((edge) =>
      edge.target === over?.id
        ? {
            ...edge,
            id: `e${edge.source}-${newId}`,
            target: newId,
            type: edge.source?.includes("If-") ? "" : "addButtonEdge",
          }
        : edge,
    )

  return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
}

export const mapNodeItem = (node: Node): INodeItem => {
  return {
    id: node.id,
    ...node.data,
  }
}

export const addBranchedNode = (
  nodes: Node[],
  edges: Edge[],
  transformedItem: Partial<IMethodItem>,
  newId: string,
  over: any,
  branches: number,
): { transformedNodes: Node[]; transformedEdges: Edge[] } => {
  // If you are adding a Decide step, attach this to data.selectNextStep
  const nextSteps: Record<string, string> = {}
  const nextStepNodeArray = []
  const nextStepEdgeArray = []

  for (let i = 1; i <= branches; i++) {
    nextSteps[newId.concat(`-B${i}`)] = ""

    // Add branch nodes
    nextStepNodeArray.push({
      id: newId.concat(`-B${i}-dropzone`),
      type: "dropzoneCard",
      data: { id: newId.concat(`-B${i}-dropzone`), title: "step" },
      position: { x: 0, y: 0 },
    })

    // Add branch edges
    nextStepEdgeArray.push({
      id: `${newId}-B${i}-edge`,
      source: newId,
      target: `${newId}-B${i}-dropzone`,
      label: i === 1 ? "True" : "False",
      type: "",
    })
  }

  const targetLowerEdge = edges.find((edge) => edge.source === over?.id)
  const targetLowerNode = nodes.find(
    (node) => node.id === targetLowerEdge?.target,
  )

  // If a node is added in the middle, there should be lower node connections
  if (targetLowerNode) {
    const updatedNodes = nodes
      .concat([
        ...nextStepNodeArray.slice(1), // Remove the first branch node, since it was replaced with the target node
      ])
      .map((node) => {
        // Replace the 'Add new step' id with the node id
        if (node.id === over?.id) {
          return {
            ...node,
            id: newId,
            type: "actionCard",
            data: {
              ...transformedItem,
              id: newId,
            },
          }
        }
        // Replace the node beneath the new node with an id repesenting the first branch
        if (node.id === targetLowerNode.id) {
          return {
            ...node,
            id: `${newId}-B1`,
          }
        }

        // If the node beneath is the last node, it has a dropzone. Update that too
        if (node.id === `${targetLowerNode.id}-dropzone`) {
          return {
            ...node,
            id: `${newId}-B1-dropzone`,
            data: { id: `${newId}-B1-dropzone`, title: "step" },
          }
        }

        return node
      })

    const updatedEdges = edges
      .concat([
        ...nextStepEdgeArray.slice(1), // Remove the first branch edge, since it was replaced with the target edge
      ])
      .map((edge) => {
        // Update the upper edge so that it connects the upper node to our branch node (parent)
        if (edge.target === over?.id) {
          return {
            ...edge,
            id: `e${edge.source}-${newId}`,
            target: `${newId}`,
            type: "addButtonEdge",
            label: "If Condition",
          }
        }
        // If the lower node is the last node, connect the dropzone instead
        if (edge.target === `${targetLowerNode.id}-dropzone`) {
          return {
            ...edge,
            id: `${newId}-B1-edge`,
            source: `${newId}-B1`,
            target: `${newId}-B1-dropzone`,
            type: "",
          }
        }
        // Update the lower edge so that it connects the lower node to the first branch of our new node
        if (edge.source === over?.id) {
          return {
            ...edge,
            id: `e${newId}-${newId}-B1`,
            source: `${newId}`,
            target: `${newId}-B1`,
            label: "True",
          }
        }
        // Update the edge connected to the lower node
        if (edge.source === targetLowerNode.id) {
          return {
            ...edge,
            id: `e${newId}-B1-${edge.target}`,
            source: `${newId}-B1`,
            type: "addButtonEdge",
            label: edge.source,
          }
        }
        return edge
      })

    return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
  }

  const updatedNodes = nodes.concat(nextStepNodeArray).map((node) =>
    node.id === over?.id
      ? {
          ...node,
          id: newId,
          type: "actionCard",
          data: {
            ...transformedItem,
          },
        }
      : node,
  )

  const updatedEdges = edges.concat(nextStepEdgeArray).map((edge) =>
    edge.target === over?.id
      ? {
          ...edge,
          id: `e${edge.source}-${newId}`,
          target: newId,
          type: /^If-\d$/.test(edge.source) ? "" : "addButtonEdge",
          label: "If Condition",
        }
      : edge,
  )

  return { transformedNodes: updatedNodes, transformedEdges: updatedEdges }
}
