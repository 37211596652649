import React, { type SetStateAction, type Dispatch } from "react"
import { Box, Typography, Checkbox, FormGroup } from "@mui/material"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ArticleIcon from "@mui/icons-material/Article"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import { ChannelButton } from "../TriggerConfig/styled"
import { colors } from "../../../../../../utils"
import FormControlLabel from "@mui/material/FormControlLabel"

interface AccordionProps {
  type: any // "channels" | "docTypes" | "channelType" | "lawyer" | "referate" | ""
  currentExpanded: string
  setCurrentExpanded: Dispatch<
    SetStateAction<
      "channels" | "docTypes" | "channelType" | "lawyer" | "referate" | ""
    >
  >

  selectAll: boolean
  items: any
  source: any
  title: string
  selectAllItems: Record<string, boolean>
  setSelectAllItems: Dispatch<SetStateAction<Record<string, boolean>>>
  querySource: any
  selectedAction: INodeItem
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  changeType: string
}

export const Accord: React.FC<AccordionProps> = (props: AccordionProps) => {
  const {
    currentExpanded,
    setCurrentExpanded,
    selectAll,
    items,
    source,
    type,
    title,
    selectAllItems,
    setSelectAllItems,
    querySource,
    selectedAction,
    handleChange,
    changeType,
  } = props

  const numberOfSelectedItems: (querySource: any, source: any) => string = (
    querySource,
    source,
  ) => {
    if (type !== "docTypes") {
      return `${querySource?.filter(
        (item: any) => source?.replace(/'/g, "")?.split(",")?.includes(item.id),
      ).length}/${querySource?.length}`
    } else
      return `${querySource?.filter(
        (item: any) =>
          source?.replace(/'/g, "")?.split(",")?.includes(item.docCategory),
      ).length}/${querySource?.length}`
  }

  const handleSelectAll: (
    items: string,
    selectAll: boolean,
    setSelectAll: Dispatch<SetStateAction<Record<string, boolean>>>,
    source: any[] | undefined,
    type: string,
  ) => void = (items, selectAll, setSelectAll, source, type) => {
    let allItems
    if (!selectAll) {
      setSelectAll((prev) => {
        return {
          ...prev,
          [items]: true,
        }
      })
      allItems = source
        ?.map((item) => {
          if (item?.id) {
            return item.id
          } else if (item?.docCategory) {
            return item.docCategory
          }
          return null
        })
        .join(",")
    } else {
      setSelectAll((prev) => {
        return {
          ...prev,
          [items]: false,
        }
      })
      allItems = "''"
    }

    handleChange(selectedAction, "input", allItems, type)
  }

  const onSelectHandler = (handledItem: any, type: string) => {
    if (handledItem?.id === "Email") return
    const updateConfig = (itemId: string, source: string) => {
      let updatedId: string

      if (source === undefined || source === "''") {
        updatedId = itemId
      } else if (source.replaceAll("'", "").split(",").includes(itemId)) {
        updatedId = source
          .replaceAll("'", "")
          .replace(new RegExp(`(^|,)${itemId}(,|$)`), "$1")
          .trim()

        if (updatedId.length === 0) {
          updatedId = "''"
        }
      } else {
        updatedId =
          source.length > 2 ? `${source.replaceAll("'", "")},${itemId}` : itemId
      }

      if (updatedId.slice(0, 1) === ",") {
        updatedId = updatedId.slice(1)
      }

      return updatedId
    }

    if (handledItem?.id) {
      const configId = updateConfig(handledItem.id, source)

      const allSelected = querySource
        ?.map((ch: any) => ch.id)
        .every((id: string) => configId.includes(id))

      setSelectAllItems((prev) => ({
        ...prev,
        [type]: allSelected,
      }))

      handleChange(selectedAction, "input", configId, changeType)
    } else if (handledItem?.docCategory) {
      const docType = updateConfig(handledItem.docCategory, source)

      const allSelectedDocs = querySource
        ?.map((doc: IDocType) => doc.docCategory)
        .every((category: string) => docType.includes(category))

      setSelectAllItems((prev) => ({
        ...prev,
        docTypes: allSelectedDocs,
      }))

      handleChange(selectedAction, "input", docType, changeType)
    }
  }

  return (
    <Accordion
      expanded={currentExpanded === type}
      sx={{
        width: "100%",
        boxShadow: "none",
        border: `1.5px solid ${
          currentExpanded === type ? colors.primary : colors.gray4
        }`,
        minHeight: "50px",
        borderRadius: "10px !important",
      }}
    >
      <AccordionSummary
        onClick={() =>
          setCurrentExpanded((prev) => (prev !== type ? type : ""))
        }
        sx={{
          overflow: "auto",
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "10px !important",
          borderBottomLeftRadius:
            currentExpanded === type ? "0px !important" : "",
          borderBottomRightRadius:
            currentExpanded === type ? "0px !important" : "",
          alignItems: "center",
          backgroundColor:
            Number(numberOfSelectedItems(querySource, source).slice(0, 1)) !== 0
              ? colors.blue6
              : currentExpanded === type
              ? colors.gray6
              : colors.white,
          mb: currentExpanded === type ? 2 : 0,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ ml: 3, fontSize: 17.5 }}>{title}</Typography>
          {currentExpanded === type && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    sx={{ mr: 1 }}
                    checked={selectAll}
                    onChange={() =>
                      handleSelectAll(
                        type,
                        selectAllItems[type],
                        setSelectAllItems,
                        querySource,
                        changeType,
                      )
                    }
                  />
                }
                label="Select all"
              />
            </FormGroup>
          )}
          {currentExpanded !== type && (
            <Typography sx={{ mr: 2 }}>
              Selected: {numberOfSelectedItems(querySource, source)}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: "295px", overflow: "auto" }}>
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {items?.length > 0 ? (
            items?.map((item: any) => {
              const arr = source.replaceAll("'", "").split([","])
              const isItemSelected =
                arr?.includes(item?.id) || arr?.includes(item.docCategory)

              return (
                <Box
                  sx={{
                    width: "48%",
                    mb: 1,
                    height: "60px",
                  }}
                  key={item.id || item.docCategory || item.description}
                >
                  <ChannelButton
                    sx={{
                      border: isItemSelected
                        ? `2px solid ${colors.primary}`
                        : `2px dashed ${colors.primary}`,
                      backgroundColor: isItemSelected
                        ? colors.blue5
                        : colors.white2,
                    }}
                    isSelected={isItemSelected}
                    onClick={() => {
                      onSelectHandler(item, type)
                    }}
                  >
                    <Checkbox
                      sx={{ margin: 0, mr: 2, ml: 3 }}
                      checked={!!isItemSelected}
                    />
                    <Box sx={{ ml: 1 }}>
                      <Typography
                        variant="regular"
                        className="line-clamp-2"
                        color={colors.black}
                      >
                        {item?.channelName || item?.value || item?.description}
                      </Typography>
                      <Typography sx={{ color: colors.gray3, fontWeight: 100 }}>
                        {item?.description || item?.docCategory}
                      </Typography>
                    </Box>
                  </ChannelButton>
                </Box>
              )
            })
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ArticleIcon sx={{ mr: 1 }} />
              No channels found
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
